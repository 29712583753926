.toolbar {
  border-radius: 0.5rem;
  background: #1c2540;
  border: none;
  font-family: "Open Sans", sans-serif;
}

.editor {
  color: #ebebf2;
  background: #1c2540;
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  height: 30rem;
  max-width: 100%;
  font-family: "Open Sans", sans-serif;
  word-break: break-all;
}

@media (max-width: 650px) {
  .editor {
    height: 15rem;
  }
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 0.5rem;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
}

.button {
  background: transparent;
  color: #f76faf;
  border: none;
  box-shadow: none !important;
}

.button.rdw-option-active {
  box-shadow: 0px 0px 5px #f073c8 !important;
}

.button img {
  filter: invert(100%);
}
